<template>
  <ln-container wrap>
    <ln-container>
      <ln-row justify="center">
        <h2>Development</h2>
      </ln-row>
    </ln-container>
    <ln-container wrap>
      <ln-container space xl="3">
        <ln-card shadow primary-light>
          <ln-card-title>My actual IP</ln-card-title>
          <ln-card-text>
            <ln-container>
              <ln-row justify="space-between">
                <span>{{$store.getters.actualIp}}</span>
              </ln-row>
            </ln-container>
          </ln-card-text>
        </ln-card>
      </ln-container>
      <ln-container space xl="5">
        <ln-card shadow primary-light>
          <ln-card-title>Uuid4</ln-card-title>
          <ln-card-text style="width: 100%">
            <ln-container>
              <ln-row wrap>
                <ln-row align="center" space>
                  <span>{{$store.getters.uuid}}</span>
                </ln-row>
                <ln-row space>
                  <ln-button width="50" height="50" primary border-radius="5" @click="$store.dispatch('GET_UUID')"><ln-icon>refresh</ln-icon></ln-button>
                </ln-row>
              </ln-row>
            </ln-container>
          </ln-card-text>
        </ln-card>
      </ln-container>
    </ln-container>
  </ln-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {GET_ACTUAL_IP, GET_UUID} from "@/store";

export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      actualIp: null
    }
  },
  methods: {
    getActualIp() {
      return 'abcd'
    }
  },
  mounted() {
    this.$store.dispatch(GET_ACTUAL_IP)
    this.$store.dispatch(GET_UUID)
  }
});
</script>
