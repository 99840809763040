<template>
  <ln-container wrap>
    <ln-container>
      <ln-row justify="center">
        <h2>Multimedia</h2>
      </ln-row>
    </ln-container>
    <ln-container wrap>
      <ln-container xl="6" space>
        <ln-card shadow primary-light>
          <ln-card-title>Create ico from Image (svg, jpg, png)</ln-card-title>
          <ln-card-text style="width: 100%">
            <ln-container>
              <ln-row justify="space-between" wrap>
                <ln-row space>
                  <ln-file-selector accept="image/png, image/gif, image/jpeg, image/svg" width="150" height="50" primary border-radius="5" v-model="selectedCreateIconFile" customLayout>
                    <ln-icon width="40" height="40">camera</ln-icon>
                    <template #fileList>
                      <!--                  <ln-container>
                                          <div v-for="file in selectedFile" v-bind:key="file" style="height: 50px; width: 50px; ">
                                          </div>
                                        </ln-container>-->
                    </template>
                  </ln-file-selector>
                  <ln-combobox width="150px" height="50" border-radius="5" primary :shadow="false" multiple :items="iconSizes" v-model="selectedIconSizes"/>
                </ln-row>
                <ln-row space>
                  <ln-button :disabled="!selectedCreateIconFile" width="200" height="50" primary @click="convertImageToIco()">
                    Start conversion
                  </ln-button>
                </ln-row>
              </ln-row>
            </ln-container>
          </ln-card-text>
        </ln-card>
      </ln-container>
      <ln-container xl="6" space>
        <ln-card shadow primary-light>
          <ln-card-title>Create website friendly images</ln-card-title>
          <ln-card-text style="width: 100%">
            <ln-container>
              <ln-row justify="space-between" wrap>
                <ln-row space>
                  <ln-file-selector accept="image/png, image/gif, image/jpeg, image/svg" width="150" height="50" primary border-radius="5" v-model="selectedCreateWebsiteImagesFile" customLayout>
                    <ln-icon width="40" height="40">camera</ln-icon>
                    <template #fileList>
                      <!--                  <ln-container>
                                          <div v-for="file in selectedFile" v-bind:key="file" style="height: 50px; width: 50px; ">
                                          </div>
                                        </ln-container>-->
                    </template>
                  </ln-file-selector>
                </ln-row>
                <ln-row space>
                  <ln-button :disabled="!selectedCreateWebsiteImagesFile" width="200" height="50" primary @click="createWebsiteFriendlyImages()">
                    Start conversion
                  </ln-button>
                </ln-row>
              </ln-row>
            </ln-container>
          </ln-card-text>
        </ln-card>
      </ln-container>
      <ln-container xl="6" space>
        <ln-card shadow primary-light>
          <ln-card-title>Convert Heic to Png</ln-card-title>
          <ln-card-text>
            <ln-container>
              <ln-row justify="space-between" wrap>
                <ln-row space>
                  <ln-file-selector accept="image/heif, image/heic" width="150" height="50" primary border-radius="5" v-model="selectedConvertHeicToPngFile" customLayout>
                    <ln-icon width="40" height="40">camera</ln-icon>
                    <template #fileList></template>
                  </ln-file-selector>
                </ln-row>
                <ln-row space>
                  <ln-button :disabled="!selectedConvertHeicToPngFile" width="200" height="50" border-radius="5" primary @click="convertHeicToPng()">
                    Start conversion
                  </ln-button>
                </ln-row>
              </ln-row>
            </ln-container>
          </ln-card-text>
        </ln-card>
      </ln-container>
    </ln-container>
  </ln-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {POST_CREATE_WEBSITE_FRIENDY_IMAGES, POST_IMAGE_FOR_HEIC_TO_PNG, POST_IMAGE_FOR_ICON} from "@/store";
import {ComboboxItem} from "@ln-cloud/ln-materials/dist/interfaces/Combobox";

export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      selectedCreateIconFile: null,
      selectedConvertHeicToPngFile: null,
      selectedCreateWebsiteImagesFile: null,
      selectedIconSizes: [16, 32, 64, 128] as number[],
      iconSizes: [
          {
            key: '16x16',
            value: 16
          } as ComboboxItem,
          {
            key: '32x32',
            value: 32
          } as ComboboxItem,
          {
            key: '64x64',
            value: 64
          } as ComboboxItem,
          {
            key: '128x128',
            value: 128
          } as ComboboxItem,
          {
            key: '256x256',
            value: 256
          } as ComboboxItem,
      ]
    }
  },
  methods: {
    convertImageToIco() {
      this.$store.dispatch(POST_IMAGE_FOR_ICON, {
        file: this.selectedCreateIconFile ? this.selectedCreateIconFile[0] : null,
        size: this.selectedIconSizes
      }).then(() => {
        this.selectedCreateIconFile = null
        this.selectedIconSizes = [16, 32, 64, 128]
      })
    },
    convertHeicToPng() {
      this.$store.dispatch(POST_IMAGE_FOR_HEIC_TO_PNG, this.selectedConvertHeicToPngFile ? this.selectedConvertHeicToPngFile[0] : null).then(() => {
        this.selectedConvertHeicToPngFile = null
      })
    },
    createWebsiteFriendlyImages() {
      this.$store.dispatch(POST_CREATE_WEBSITE_FRIENDY_IMAGES, this.selectedCreateWebsiteImagesFile ? this.selectedCreateWebsiteImagesFile[0] : null).then(() => {
        this.selectedCreateWebsiteImagesFile = null
      })
    }
  }
});
</script>
