<template>
  <ln-container wrap>
    <ln-container>
      <ln-row justify="center">
        <h2>Photography</h2>
      </ln-row>
    </ln-container>
    <ln-container>
      <ln-container xl="5" lg="8">
        <ln-card shadow primary-light>
          <ln-card-title>Calculate zoom factor</ln-card-title>
          <ln-card-text>
            <ln-container>
              <ln-col wrap>
                <ln-text-box height="40px" placeholder="Focal Length (mm)" v-model="focalLength"></ln-text-box>
                <ln-text-box height="40px" placeholder="Crop Factor" v-model="cropFactor"></ln-text-box>
                <ln-text-box height="40px" placeholder="Magnification (times)" v-model="magnification"></ln-text-box>
                <ln-row justify="space-between" space>
                  <ln-button height="50" primary @click="clearZoomFactorCalc()">Clear</ln-button>
                  <ln-button height="50" primary @click="calcZoomFactor()">Calculate</ln-button>
                </ln-row>
              </ln-col>
            </ln-container>
          </ln-card-text>
        </ln-card>
      </ln-container>
    </ln-container>
  </ln-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      focalLength: '',
      cropFactor: '',
      magnification: ''
    }
  },
  methods: {
    calcZoomFactor() {
      if (this.focalLength && this.cropFactor) {
        this.magnification = (Number(this.cropFactor) * Number(this.focalLength) / Math.sqrt(1872)).toFixed(1)
      } else if (this.focalLength && this.magnification) {
        this.cropFactor = ((Math.sqrt(1872) * Number(this.magnification)) / Number(this.focalLength)).toFixed(1)
      } else if (this.cropFactor && this.magnification) {
        this.focalLength = (Number(this.magnification) / Number(this.cropFactor) * Math.sqrt(1872)).toFixed(1)
      }
    },
    clearZoomFactorCalc() {
      this.focalLength = ''
      this.cropFactor = ''
      this.magnification = ''
    }
  },
  watch: {
    focalLength() {
      this.focalLength = this.focalLength.replace(',', '.')
    },
    cropFactor() {
      this.cropFactor = this.cropFactor.replace(',', '.')
    },
    magnification() {
      this.magnification = this.magnification.replace(',', '.')
    }
  }
});
</script>
<style lang="scss">
</style>
