import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from "@/views/HomeView.vue";
import Multimedia from "@/views/Multimedia.vue";
import Development from "@/views/Development.vue";
import Photography from "@/views/Photography.vue";
import Electronics from "@/views/Electronics.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: {
      displayName: 'Home'
    },
    component: HomeView
  },
  {
    path: '/multimedia',
    name: 'multimedia',
    meta: {
      displayName: 'Multimedia'
    },
    component: Multimedia
  },
  {
    path: '/development',
    name: 'development',
    meta: {
      displayName: 'Development'
    },
    component: Development
  },
  {
    path: '/photography',
    name: 'photography',
    meta: {
      displayName: 'Photography'
    },
    component: Photography
  },
  {
    path: '/electronics',
    name: 'electronics',
    meta: {
      displayName: 'Electronics'
    },
    component: Electronics
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
