<template>
  <ln-app>
    <ln-burger-menu v-model="menuOpen" width="200px" secondary height="100%">
      <ln-col xl="2" v-for="entry in $router.getRoutes()" v-bind:key="entry">
        <ln-button border-radius="0" :shadow="false" width="100%" secondary @click="$router.push(entry); menuOpen = false">
          {{ entry.meta.displayName }}
        </ln-button>
      </ln-col>
    </ln-burger-menu>
    <ln-container xl="10" wrap>
      <ln-container>
        <ln-header xl="16" secondary height="50px">
          <ln-icon secondary hide-in="lg, xl" icon="menu" height="50" width="50" @click="menuOpen = !menuOpen">menu</ln-icon>
          <ln-col xl="2" hide-in="xs, sm , md" v-for="entry in $router.getRoutes()" v-bind:key="entry">
            <ln-button height="50" border-radius="0" :shadow="false" width="100%" secondary @click="$router.push(entry)">
              {{ entry.meta.displayName }}
            </ln-button>
          </ln-col>
        </ln-header>
      </ln-container>
      <router-view/>
    </ln-container>
  </ln-app>
</template>
<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: 'AppView',
  data() {
    return {
      menuOpen: false
    }
  }
})
</script>
<style lang="scss">
@font-face {
  font-family: "Montserrat";
  src: url(../src/assets/fonts/Montserrat-Light.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
.ln-header {
  position: relative !important;
}
</style>
