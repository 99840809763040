<template>
  <ln-container wrap>
    <ln-container>
      <ln-row justify="center">
        <h2>Electronics</h2>
      </ln-row>
    </ln-container>
    <ln-container xl="5" lg="8" space>
      <ln-container>
        <ln-card shadow primary-light>
          <ln-card-title>Calculate cable thickness</ln-card-title>
          <ln-card-text>
            <ln-container>
              <ln-col wrap>
                <ln-text-box height="40px" placeholder="Cable length (m)" v-model="calcCableCrossSection.cableLength"></ln-text-box>
                <ln-text-box height="40px" placeholder="Amperage (A)" v-model="calcCableCrossSection.ampere"></ln-text-box>
                <ln-text-box height="40px" placeholder="Dissipation factor (%)" v-model="calcCableCrossSection.dissipation"></ln-text-box>
                <ln-text-box height="40px" placeholder="Voltage (V)" v-model="calcCableCrossSection.voltage"></ln-text-box>
                <ln-spacer height="20"></ln-spacer>
                <span>Result: {{ calcCableCrossSection.result }}</span>
                <ln-spacer height="20"></ln-spacer>
                <ln-row justify="space-between" space>
                  <ln-button height="50" primary @click="onClearCableCrossSection()">Clear</ln-button>
                  <ln-button height="50" primary @click="onCalcCableCrossSection()">Calculate</ln-button>
                </ln-row>
              </ln-col>
            </ln-container>
          </ln-card-text>
        </ln-card>
      </ln-container>
    </ln-container>
    <ln-container xl="5" lg="8" space>
      <ln-container>
        <ln-card shadow primary-light>
          <ln-card-title>Calculate power</ln-card-title>
          <ln-card-text>
            <ln-container>
              <ln-col wrap>
                <ln-text-box height="40px" placeholder="Ampere (A)" v-model="calcPower.ampere"></ln-text-box>
                <ln-text-box height="40px" placeholder="Voltage (V)" v-model="calcPower.voltage"></ln-text-box>
                <ln-text-box height="40px" placeholder="Power (W)" v-model="calcPower.power"></ln-text-box>
                <ln-spacer height="100"></ln-spacer>
                <ln-row justify="space-between" space>
                  <ln-button height="50" primary @click="onClearCalcPower()">Clear</ln-button>
                  <ln-button height="50" primary @click="onCalcPower()">Calculate</ln-button>
                </ln-row>
              </ln-col>
            </ln-container>
          </ln-card-text>
        </ln-card>
      </ln-container>
    </ln-container>
  </ln-container>
</template>

<script>
export default {
  name: 'ElectronicsView',
  data() {
    return {
      calcPower: {
        ampere: '',
        voltage: '',
        power: ''
      },
      calcCableCrossSection: {
        cableLength: '',
        ampere: '',
        dissipation: '',
        voltage: '',
        result: ''
      },
    }
  },
  methods: {
    onClearCalcPower() {
      this.calcPower.ampere = ''
      this.calcPower.voltage = ''
      this.calcPower.power = ''
    },
    onCalcPower() {
      if (this.calcPower.ampere && this.calcPower.voltage) {
        this.calcPower.power = Math.round(this.calcPower.ampere * this.calcPower.voltage * 100) / 100
      } else if (this.calcPower.power && this.calcPower.voltage) {
        this.calcPower.ampere = Math.round(this.calcPower.power / this.calcPower.voltage * 100) / 100
      } else if (this.calcPower.ampere && this.calcPower.power) {
        this.calcPower.voltage = Math.round(this.calcPower.power / this.calcPower.ampere * 100) / 100
      }
    },
    onClearCableCrossSection() {
      this.calcCableCrossSection.result = ''
    },
    onCalcCableCrossSection() {
      this.calcCableCrossSection.result = String(
          Math.round((
              (this.calcCableCrossSection.ampere * 0.0175 * this.calcCableCrossSection.cableLength * 2) /
              (this.calcCableCrossSection.dissipation * this.calcCableCrossSection.voltage)
          ) * 10000) / 100) + "mm²"
    }
  }
}
</script>

<style scoped>

</style>