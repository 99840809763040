import { createStore } from 'vuex'
import axios from "axios";

export const POST_IMAGE_FOR_ICON = 'POST_IMAGE_FOR_ICON'
export const POST_IMAGE_FOR_HEIC_TO_PNG = 'POST_IMAGE_FOR_HEIC_TO_PNG'
export const POST_CREATE_WEBSITE_FRIENDY_IMAGES = 'POST_CREATE_WEBSITE_FRIENDY_IMAGES'
export const GET_ACTUAL_IP = 'GET_ACTUAL_IP'
export const UPDATE_ACTUAL_IP = 'UPDATE_ACTUAL_IP'
export const GET_UUID = 'GET_UUID'
export const UPDATE_UUID = 'UPDATE_UUID'

export default createStore({
  state: {
    main: {
      development: {
        actual_ip: null,
        uuid: null
      }
    }
  },
  getters: {
    actualIp: state => state.main.development.actual_ip,
    uuid: state => state.main.development.uuid
  },
  mutations: {
    [UPDATE_ACTUAL_IP](state, ipAddress) {
      state.main.development.actual_ip = ipAddress
    },
    [UPDATE_UUID](state, uuid) {
      state.main.development.uuid = uuid
    }
  },
  actions: {
    async [POST_IMAGE_FOR_ICON](_, crate_icon_payload) {
      await axios.post(
          `${process.env.VUE_APP_API_URI}/multimedia/create_icon`, {
            file: crate_icon_payload.file,
            sizes: crate_icon_payload.size.join()
          }, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            responseType: "blob"
          }
      ).then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]))
        const tag = document.createElement("a")
        tag.href = url

        tag.setAttribute('download', response.headers['file-name'])
        tag.click()
      })
    },
    async [POST_IMAGE_FOR_HEIC_TO_PNG](_, file) {
      axios.post(
          `${process.env.VUE_APP_API_URI}/multimedia/convert_heic_to_png`, {
            file: file
          }, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            responseType: "blob"
          }
      ).then(response => {
        const url = URL.createObjectURL(new Blob([response.data]))
        const tag = document.createElement("a")
        tag.href = url
        tag.setAttribute('download', response.headers['file-name'])
        tag.click()
      })
    },
    async [POST_CREATE_WEBSITE_FRIENDY_IMAGES](_, file) {
      axios.post(
          `${process.env.VUE_APP_API_URI}/multimedia/create_website_friendly_images`, {
            file: file
          }, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            responseType: "blob"
          }
      ).then(response => {
        const url = URL.createObjectURL(new Blob([response.data]))
        const tag = document.createElement("a")
        tag.href = url
        tag.setAttribute('download', response.headers['file-name'])
        tag.click()
      })
    },
    async [GET_ACTUAL_IP]({ commit }) {
      axios.get(
          `${process.env.VUE_APP_API_URI}/development/get_actual_ip`
      ).then(response => {
        commit(UPDATE_ACTUAL_IP, response.data)
      })
    },
    async [GET_UUID]({ commit }) {
      axios.get(
          `${process.env.VUE_APP_API_URI}/development/get_uuid`
      ).then(response => {
        commit(UPDATE_UUID, response.data)
      })
    }
  },
  modules: {
  }
})
