import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {LnMaterialsPlugin} from "@ln-cloud/ln-materials";

const app = createApp(App)
app.use(store)
app.use(router)
app.use(LnMaterialsPlugin, {
    colors: {
        primary: '#b3e5fc',
        primaryLight: '#e6ffff',
        primaryDark: '#82b3c9',
        secondary: '#8d6e63',
        secondaryLight: '#be9c91',
        secondaryDark: '#5f4339'
    },
    textColor: '#2c3e50',
    fontFamily: '"Montserrat",  Avenir, Helvetica, Arial, sans-serif;',
    fontWeight: 'bold'
})
app.mount('#app')
