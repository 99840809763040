<template>
  <ln-container wrap>
    <ln-row justify="center">
      <h1>My Tool Engine</h1>
    </ln-row>
    <ln-row justify="center">
      <h3>One place, for all calculation, small helpers and much more.</h3>
    </ln-row>
  </ln-container>
  <ln-container>
    <ln-row justify="center">
      <p>We are still under development. Come back later to see more helpful tools.</p>
    </ln-row>
  </ln-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
});
</script>
