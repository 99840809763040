import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ln_button = _resolveComponent("ln-button")!
  const _component_ln_col = _resolveComponent("ln-col")!
  const _component_ln_burger_menu = _resolveComponent("ln-burger-menu")!
  const _component_ln_icon = _resolveComponent("ln-icon")!
  const _component_ln_header = _resolveComponent("ln-header")!
  const _component_ln_container = _resolveComponent("ln-container")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ln_app = _resolveComponent("ln-app")!

  return (_openBlock(), _createBlock(_component_ln_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ln_burger_menu, {
        modelValue: _ctx.menuOpen,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuOpen) = $event)),
        width: "200px",
        secondary: "",
        height: "100%"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$router.getRoutes(), (entry) => {
            return (_openBlock(), _createBlock(_component_ln_col, {
              xl: "2",
              key: entry
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ln_button, {
                  "border-radius": "0",
                  shadow: false,
                  width: "100%",
                  secondary: "",
                  onClick: ($event: any) => {_ctx.$router.push(entry); _ctx.menuOpen = false}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(entry.meta.displayName), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createTextVNode(),
      _createVNode(_component_ln_container, {
        xl: "10",
        wrap: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ln_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_ln_header, {
                xl: "16",
                secondary: "",
                height: "50px"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ln_icon, {
                    secondary: "",
                    "hide-in": "lg, xl",
                    icon: "menu",
                    height: "50",
                    width: "50",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.menuOpen = !_ctx.menuOpen))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("menu")
                    ]),
                    _: 1
                  }),
                  _createTextVNode(),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$router.getRoutes(), (entry) => {
                    return (_openBlock(), _createBlock(_component_ln_col, {
                      xl: "2",
                      "hide-in": "xs, sm , md",
                      key: entry
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ln_button, {
                          height: "50",
                          "border-radius": "0",
                          shadow: false,
                          width: "100%",
                          secondary: "",
                          onClick: ($event: any) => (_ctx.$router.push(entry))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(entry.meta.displayName), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createTextVNode(),
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}